.pdfContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.viewerAndToolbarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.viewerContainer {
    width: 900px;
    height: 85vh;
    /* Ajusta el tamaño vertical del visor según tus necesidades */
}

.toolbarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #316094;
    padding: 10px 0;
    color: white;
    height: 85vh;
}

.toolbarContainer div {
    margin: 0 5px;
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
    .pdfContainer {
        height: calc(100% - 123px);
    }
}

/* Media query for large screens */
@media only screen and (min-width: 901px) {
    .pdfContainer {
        height: calc(100% - 123px);
    }
}

.viewerContainer::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.rpv-core__icon {
    color: white !important;
    stroke-width: 2 !important;
}

.rpv-core__textbox {
    width: 40% !important;
    color: #316094 !important;
    border: 10px solid transparent;
    border-radius: 0 !important;
    margin-bottom: 10px !important;
}

.rpv-core__inner-page {
    background-color: #F0F0F2 !important;
}

.textPdfViewer {
    background-color: white;
    font-size: 15px;
    color: #316094;
    font-size: 16px;
    font-family: robotoCondensedItalic;
    padding: 20px;
}

.rpv-page-navigation__current-page-input {
    margin: 0;
    text-align: center;
    width: 7rem;
}