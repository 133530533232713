html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}
.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root{
  background-color: red !important;
}
#root {
  height: 100%;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.MainContent {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #356396;
  width: 100%;
}

.App-link {
  color: #356396;
}
.css-y02fik-MuiButtonBase-root-MuiButton-root:hover{
  background-color: #356396 !important;
}